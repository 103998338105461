import gsap from 'gsap'

export default function Trail(){
  // body element
  const body = document.body;

  // helper functions
  const MathUtils = {
    // linear interpolation
    lerp: (a, b, n) => (1 - n) * a + n * b,
    // distance between two points
    distance: (x1,y1,x2,y2) => Math.hypot(x2-x1, y2-y1),
    // Random float
    getRandomFloat: (min, max) => (Math.random() * (max - min) + min).toFixed(2)
  }

  // calculate the viewport size
  let winsize;
  const calcWinsize = () => winsize = {width: window.innerWidth, height: window.innerHeight};
  calcWinsize();
  // and recalculate on resize
  window.addEventListener('resize', calcWinsize);

  // get the mouse position
  const getMousePos = (ev) => {
    let posx = 0;
    let posy = 0;
    if (!ev) ev = window.event;
    if (ev.pageX || ev.pageY) {
      posx = ev.pageX;
      posy = ev.pageY;
    }
    else if (ev.clientX || ev.clientY) 	{
      posx = ev.clientX + body.scrollLeft + docEl.scrollLeft;
      posy = ev.clientY + body.scrollTop + docEl.scrollTop;
    }
    return {x: posx, y: posy};
  }

  // mousePos: current mouse position
  // cacheMousePos: previous mouse position
  // lastMousePos: last last recorded mouse position (at the time the last image was shown)
  let mousePos = {x: 0, y: 0}
  let lastMousePos = {x: 0, y: 0}
  let cacheMousePos = {x: 0, y: 0}
  
  // update the mouse position
  window.addEventListener('mousemove', ev => mousePos = getMousePos(ev));
  
  // gets the distance from the current mouse position to the last recorded mouse position
  const getMouseDistance = () => MathUtils.distance(mousePos.x,mousePos.y,lastMousePos.x,lastMousePos.y);

  class Image {
    constructor(el) {
      this.DOM = {el: el};
      // image deafult styles
      this.defaultStyle = {
        rotation: 0,
        x: 0,
        y: 0,
        opacity: 0
      };
      // get sizes/position
      this.getRect();
      // init/bind events
      this.initEvents();
    }
    initEvents() {
      // on resize get updated sizes/position
      window.addEventListener('resize', () => this.resize());
    }
    resize() {
      // reset styles
      gsap.set(this.DOM.el, this.defaultStyle);
      // get sizes/position
      this.getRect();
    }
    getRect() {
      this.rect = this.DOM.el.getBoundingClientRect();
    }
    isActive() {
      // check if image is animating or if it's visible
      return gsap.isTweening(this.DOM.el) || this.DOM.el.style.opacity != 0;
    }
    setRatio() {
      //this.DOM.el.style.setProperty('--img-maxwidth', `${MathUtils.getRandomFloat(150,350)}px`);
      // get sizes/position
      this.getRect();
    }
  }

  class ImageTrail {
    constructor() {
      // images container
      this.DOM = {content: document.querySelector('.c-trail')};
      // array of Image objs, one per image element
      this.images = [];
      [...this.DOM.content.querySelectorAll('img')].forEach(img => this.images.push(new Image(img)));
      // total number of images
      this.imagesTotal = this.images.length;
      // upcoming image index
      this.imgPosition = 0;
      // zIndex value to apply to the upcoming image
      this.zIndexVal = 1;
      // mouse distance required to show the next image
      this.threshold = 140;
      // render the images
      requestAnimationFrame(() => this.render());
    }
    render() {
      // get distance between the current mouse position and the position of the previous image
      let distance = getMouseDistance();
      // cache previous mouse position
      cacheMousePos.x = MathUtils.lerp(cacheMousePos.x || mousePos.x, mousePos.x, 0.1);
      cacheMousePos.y = MathUtils.lerp(cacheMousePos.y || mousePos.y, mousePos.y, 0.1);

      // if the mouse moved more than [this.threshold] then show the next image
      if ( distance > this.threshold ) {
        this.showNextImage();
        ++this.zIndexVal;
        this.imgPosition = this.imgPosition < this.imagesTotal-1 ? this.imgPosition+1 : 0;
        lastMousePos = mousePos;
      }

      // check when mousemove stops and all images are inactive (not visible and not animating)
      let isIdle = true;
      for (let img of this.images) {
        if ( img.isActive() ) {
          isIdle = false;
          break;
        }
      }
      // reset z-index initial value
      if ( isIdle && this.zIndexVal !== 1 ) {
        this.zIndexVal = 1;
      }

      // loop..
      requestAnimationFrame(() => this.render());
    }
    showNextImage() {
      // show image at position [this.imgPosition]
      const img = this.images[this.imgPosition];
      img.setRatio();
      // kill any tween on the image
      gsap.killTweensOf(img.DOM.el);
      const trailEffect = gsap.timeline();
      // show the image
      trailEffect.set(img.DOM.el, {
        startAt: {opacity: 0},
        opacity: 1,
        rotation: 0,
        zIndex: this.zIndexVal,
        x: cacheMousePos.x - img.rect.width/2,
        y: cacheMousePos.y - img.rect.height/2
      }, 0)
      // animate position
      trailEffect.to(img.DOM.el, {
        duration: 1.5,
        ease: "expo.out",
        x: mousePos.x - img.rect.width/2,
        y: mousePos.y - img.rect.height/2
      }, 0)
      // then make it disappear
      trailEffect.to(img.DOM.el, {
        duration: 0.75,
        ease: "power2.inOut",
        opacity: 0
      }, 0.7)
      // translate down the image
      trailEffect.to(img.DOM.el, {
        duration: 1,
        ease: "power2.inOut",
        x: `+=${MathUtils.getRandomFloat(-1*(winsize.width + img.rect.width/2), winsize.width + img.rect.width/2)}`,
        y: `+=${MathUtils.getRandomFloat(-1*(winsize.height + img.rect.height/2), winsize.height + img.rect.height/2)}`,
        rotation: MathUtils.getRandomFloat(-20,20)
      }, 0.7);
    }
  }

  new ImageTrail()

  /********** Preload stuff **********/

  // Preload images
  /* const preloadImages = () => {
    return new Promise((resolve, reject) => {
      imagesLoaded(document.querySelectorAll('.content__img'), resolve);
    });
  };
  
  // And then..
  preloadImages().then(() => {
    // Remove the loader
    document.body.classList.remove('loading');
    new ImageTrail();
  }); */
}

if(document.querySelector('.c-trail')){
  Trail()
}