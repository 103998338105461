import * as OfflinePluginRuntime from 'offline-plugin/runtime';

// HTML
import './index.html';
import './about.html';
import './all.html';
import './vessel.html';

// Images
import './images/blister/blister-0.png';
import './images/blister/blister-1.png';
import './images/blister/blister-2.png';
import './images/blister/blister-3.png';
import './images/blister/blister-4.png';
import './images/blister/blister-5.png';
import './images/blister/blister-6.png';
import './images/blister/blister-7.png';
import './images/blister/blister-8.png';
import './images/blister/blister-9.png';
import './images/blister/blister-10.png';
import './images/blister/blister-11.png';
import './images/blister/blister-12.png';
import './images/cover/cover-0.jpg';
import './images/cover/cover-1.jpg';
import './images/cover/cover-2.jpg';
import './images/cover/cover-3.jpg';
import './images/cover/cover-4.jpg';
import './images/cover/cover-5.jpg';
import './images/cover/cover-6.jpg';
import './images/cover/cover-7.jpg';
import './images/cover/cover-8.jpg';
import './images/cover/cover-9.jpg';
import './images/cover/cover-10.jpg';
import './images/cover/cover-11.jpg';
import './images/cover/cover-12.jpg';
import './images/veil/veil-1.jpg';
import './images/veil/veil-2.jpg';
import './images/veil/veil-3.jpg';
import './images/veil/veil-4.jpg';
import './images/veil/veil-5.jpg';
import './images/veil/veil-6.jpg';
import './images/veil/veil-7.jpg';
import './images/veil/veil-8.jpg';
import './images/veil/veil-9.jpg';
import './images/veil/veil-10.jpg';
import './images/veil/veil-11.jpg';
import './images/veil/veil-12.jpg';
import './images/veil/veil-13.jpg';
import './images/veil/veil-14.jpg';
import './images/veil/veil-15.jpg';
import './images/veil/veil-16.jpg';
import './images/veil/veil-17.jpg';
import './images/veil/veil-18.jpg';
import './images/veil/veil-19.jpg';
import './images/vessel/vessel-1.jpg';
import './images/vessel/vessel-2.jpg';
import './images/vessel/vessel-3.jpg';
import './images/vessel/vessel-4.jpg';
import './images/vessel/vessel-5.jpg';
import './images/vessel/vessel-6.jpg';
import './images/vessel/vessel-7.jpg';
import './images/vessel/vessel-8.jpg';
import './images/vessel/vessel-9.jpg';
import './images/vessel/vessel-10.jpg';
import './images/vessel/vessel-11.jpg';
import './images/vessel/vessel-12.jpg';
import './images/vessel/vessel-13.jpg';
import './images/vessel/vessel-14.jpg';
import './images/vessel/vessel-15.jpg';
import './images/vessel/vessel-16.jpg';
import './images/ny-street/ny-street-1.jpg'
import './images/ny-street/ny-street-2.jpg'
import './images/ny-street/ny-street-3.jpg'
import './images/ny-street/ny-street-4.jpg'
import './images/ny-street/ny-street-5.jpg'
import './images/ny-street/ny-street-6.jpg'
import './images/ny-street/ny-street-7.jpg'
import './images/ny-street/ny-street-8.jpg'
import './images/ny-street/ny-street-9.jpg'
import './images/ny-street/ny-street-10.jpg'
import './images/ny-street/ny-street-11.jpg'
import './images/ny-street/ny-street-12.jpg'
import './images/ny-street/ny-street-13.jpg'
import './images/ny-street/ny-street-14.jpg'
import './images/aerial/aerial-1.jpg'
import './images/aerial/aerial-2.jpg'
import './images/aerial/aerial-3.jpg'
import './images/aerial/aerial-4.jpg'
import './images/aerial/aerial-5.jpg'
import './images/aerial/aerial-6.jpg'
import './images/aerial/aerial-7.jpg'
import './images/aerial/aerial-8.jpg'
import './images/aerial/aerial-9.jpg'
import './images/aerial/aerial-10.jpg'
import './images/aerial/aerial-11.jpg'
import './images/aerial/aerial-12.jpg'
import './images/aerial/aerial-13.jpg'
import './images/aerial/aerial-14.jpg'
import './images/aerial/aerial-15.jpg'
import './images/aerial/aerial-16.jpg'
import './images/aerial/aerial-17.jpg'
import './images/aerial/aerial-18.jpg'
import './images/aerial/aerial-19.jpg'
import './images/aerial/aerial-20.jpg'
import './images/aerial/aerial-20.jpg'
import './images/london/london-1.jpg'
import './images/london/london-2.jpg'
import './images/london/london-3.jpg'
import './images/london/london-4.jpg'
import './images/london/london-5.jpg'
import './images/london/london-6.jpg'
import './images/london/london-7.jpg'
import './images/london/london-8.jpg'
import './images/london/london-9.jpg'
import './images/london/london-10.jpg'
import './images/london/london-11.jpg'
import './images/london/london-12.jpg'
import './images/london/london-13.jpg'
import './images/london/london-14.jpg'
import './images/london/london-15.jpg'
import './images/london/london-16.jpg'
import './images/london/london-17.jpg'
import './images/london/london-18.jpg'
import './images/london/london-19.jpg'
import './images/london/london-20.jpg'
import './images/london/london-21.jpg'
import './images/london/london-22.jpg'
import './images/london/london-23.jpg'
import './images/london/london-24.jpg'
import './images/london/london-25.jpg'
import './images/london/london-26.jpg'
import './images/london/london-27.jpg'
import './images/london/london-28.jpg'
import './images/london/london-29.jpg'
import './images/london/london-30.jpg'
import './images/milano/milano-1.jpg'
import './images/milano/milano-2.jpg'
import './images/milano/milano-3.jpg'
import './images/milano/milano-4.jpg'
import './images/milano/milano-5.jpg'
import './images/milano/milano-6.jpg'
import './images/milano/milano-7.jpg'
import './images/milano/milano-8.jpg'
import './images/milano/milano-9.jpg'
import './images/milano/milano-10.jpg'
import './images/milano/milano-11.jpg'
import './images/milano/milano-12.jpg'
import './images/milano/milano-13.jpg'
import './images/milano/milano-14.jpg'
import './images/milano/milano-15.jpg'
import './images/milano/milano-16.jpg'
import './images/milano/milano-17.jpg'
import './images/gotham/gotham-1.jpg'
import './images/gotham/gotham-2.jpg'
import './images/gotham/gotham-3.jpg'
import './images/gotham/gotham-4.jpg'
import './images/gotham/gotham-5.jpg'
import './images/gotham/gotham-6.jpg'
import './images/gotham/gotham-7.jpg'
import './images/gotham/gotham-8.jpg'
import './images/gotham/gotham-9.jpg'
import './images/gotham/gotham-10.jpg'
import './images/gotham/gotham-11.jpg'
import './images/gotham/gotham-12.jpg'
import './images/gotham/gotham-13.jpg'
import './images/gotham/gotham-14.jpg'
import './images/gotham/gotham-15.jpg'
import './images/gotham/gotham-16.jpg'
import './images/gotham/gotham-17.jpg'
import './images/gotham/gotham-18.jpg'
import './images/gotham/gotham-19.jpg'
import './images/cooper/cooper-1.jpg'
import './images/cooper/cooper-2.jpg'
import './images/cooper/cooper-3.jpg'
import './images/cooper/cooper-4.jpg'
import './images/cooper/cooper-5.jpg'
import './images/cooper/cooper-6.jpg'
import './images/cooper/cooper-7.jpg'
import './images/cooper/cooper-8.jpg'
import './images/cooper/cooper-9.jpg'
import './images/cooper/cooper-10.jpg'
import './images/lines/lines-1.jpg'
import './images/lines/lines-2.jpg'
import './images/lines/lines-3.jpg'
import './images/lines/lines-4.jpg'
import './images/lines/lines-5.jpg'
import './images/lines/lines-6.jpg'
import './images/lines/lines-7.jpg'
import './images/lines/lines-8.jpg'
import './images/lines/lines-9.jpg'
import './images/lines/lines-10.jpg'
import './images/ny-life1/ny-life1-1.jpg'
import './images/ny-life1/ny-life1-2.jpg'
import './images/ny-life1/ny-life1-3.jpg'
import './images/ny-life1/ny-life1-4.jpg'
import './images/ny-life1/ny-life1-5.jpg'
import './images/ny-life1/ny-life1-6.jpg'
import './images/ny-life1/ny-life1-7.jpg'
import './images/ny-life1/ny-life1-8.jpg'
import './images/ny-life1/ny-life1-9.jpg'
import './images/ny-life1/ny-life1-10.jpg'
import './images/ny-life1/ny-life1-11.jpg'
import './images/ny-life1/ny-life1-12.jpg'
import './images/ny-life1/ny-life1-13.jpg'
import './images/ny-life1/ny-life1-14.jpg'
import './images/ny-life1/ny-life1-15.jpg'
import './images/ny-life2/ny-life2-1.jpg'
import './images/ny-life2/ny-life2-2.jpg'
import './images/ny-life2/ny-life2-3.jpg'
import './images/ny-life2/ny-life2-4.jpg'
import './images/ny-life2/ny-life2-5.jpg'
import './images/ny-life2/ny-life2-6.jpg'
import './images/ny-life2/ny-life2-7.jpg'
import './images/ny-life2/ny-life2-8.jpg'
import './images/ny-life2/ny-life2-9.jpg'
import './images/ny-life2/ny-life2-10.jpg'
import './images/ny-life2/ny-life2-11.jpg'
import './images/ny-life2/ny-life2-12.jpg'
import './images/ny-life2/ny-life2-13.jpg'
import './images/ny-life2/ny-life2-14.jpg'
import './images/ny-life2/ny-life2-15.jpg'
import './images/ny-life2/ny-life2-16.jpg'
import './images/ny-life2/ny-life2-17.jpg'
import './images/ny-life2/ny-life2-18.jpg'
import './images/oculus/oculus-1.jpg'
import './images/oculus/oculus-2.jpg'
import './images/oculus/oculus-3.jpg'
import './images/oculus/oculus-4.jpg'
import './images/oculus/oculus-5.jpg'
import './images/oculus/oculus-6.jpg'
import './images/oculus/oculus-7.jpg'
import './images/oculus/oculus-8.jpg'
import './images/oculus/oculus-9.jpg'
import './images/oculus/oculus-10.jpg'
import './images/oculus/oculus-11.jpg'
import './images/oculus/oculus-12.jpg'
import './images/oculus/oculus-13.jpg'
import './images/oculus/oculus-14.jpg'
import './images/oculus/oculus-15.jpg'
import './images/oculus/oculus-16.jpg'
import './images/ny-blck/ny-blck-1.jpg'
import './images/ny-blck/ny-blck-2.jpg'
import './images/ny-blck/ny-blck-3.jpg'
import './images/ny-blck/ny-blck-4.jpg'
import './images/ny-blck/ny-blck-5.jpg'
import './images/ny-blck/ny-blck-6.jpg'
import './images/ny-blck/ny-blck-7.jpg'
import './images/ny-blck/ny-blck-8.jpg'
import './images/ny-blck/ny-blck-9.jpg'
import './images/ny-blck/ny-blck-10.jpg'
import './images/ny-blck/ny-blck-11.jpg'
import './images/ny-blck/ny-blck-12.jpg'
import './images/ny-blck/ny-blck-13.jpg'
import './images/intro-bg.png';
import './images/calling-card.png';
import './images/trail/trail-1.jpg';
import './images/trail/trail-2.jpg';
import './images/trail/trail-3.jpg';
import './images/trail/trail-4.jpg';
import './images/trail/trail-5.jpg';
import './images/trail/trail-6.jpg';
import './images/trail/trail-7.jpg';
import './images/about/grid-logos.png';
import './images/external-link.svg';
import './images/signature.svg';
import './images/logo.svg';
import './images/big-logo.svg';
import './images/favicon.jpg'

// CSS
import './index.scss';
// JS
import './scripts/main';
// Fonts
import './fonts/Ekster-Regular.otf';
import './fonts/Ekster-Bold.otf';
import './fonts/RoxboroughCF-Bold.otf';
import './fonts/RoxboroughCF-Medium.otf';
import './fonts/RoxboroughCF-Thin.otf';
import './fonts/RoxboroughCF-ThinItalic.otf';
import './fonts/Wrangler.woff';
import './fonts/Wrangler.woff2';

OfflinePluginRuntime.install();