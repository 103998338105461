import gsap from 'gsap'

export default function Project(){
  const isDevice = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i);
  const eProject = gsap.timeline()
  eProject
  .set(".c-layer", {scaleY: 0})
  .set(".l-item", {opacity: 0})
  .set(".t-head h2 span", {y: "37vh"})
  .to(".c-head img", {duration: 1, delay: 2, y: "90vh", ease: "expo.inOut", onComplete: () => {
    document.querySelector('.c-cover').style.opacity = 0
  }})
  .to(".c-layer", {duration: 1.5, scaleY: 1, ease: "expo.inOut"}, 2.5)
  .to(".o-big", {duration: 1, y: "-46vh", ease: "power2.inOut"}, 3)
  .to(".t-head h2 span", {duration: 1, y: 0, stagger: 0.075, ease: "power2.inOut"}, 3.5)
  .to(".l-item", {duration: 1, opacity: 1, ease: "power2.inOut"}, 3.5)
  .to(".container-project", {duration: 0.1, cursor: "initial"}, 3.5)
}

/* if(document.querySelector('.container-project')){
  Project()
} */