import gsap from 'gsap'
import SplitText from '../libs/SplitText.min'

export default function Intro(){
  gsap.registerPlugin(SplitText)
  const cIntroTitle = new SplitText(".c-intro__title", {type: 'chars'})

  const intro = gsap.timeline()
  intro
  .set(".c-intro__point", {x: -120, opacity: 0, scaleX: 2})
  .set(".c-trail, .intro-bg, .footer, .header", {opacity: 0})
  .set(".header", {y: -200})
  .set(".time", {y: 200})
  .set(cIntroTitle.chars, {opacity: 0})
  .to(".c-intro", {opacity: 1, duration: 0.5})
  .to(".intro-bg", {duration: 4, opacity: 1, ease: "power2.inOut"})
  .to(".c-intro__point", {opacity: 1, duration: 0.01, ease: "power2.inOut"}, 1.5)
  .to(".c-intro__point", {x: 0, scaleX: 1, duration: 3, ease: "expo.inOut"}, 1)
  .to(cIntroTitle.chars, {duration: 1, opacity: 1, stagger: 0.075, ease: "expo.inOut"}, 2)
  .to(".c-trail", {duration: 1, opacity: 1, ease: "power2.inOut"}, 3)
  /* .to(".intro", {opacity: 0, duration: 1, ease: "power2.inOut"}, 5.5)
  .to(".time, .header", {opacity: 1, y: 0, duration: 2.5, ease: "power3.inOut", onStart: () => {
    if(document.querySelector('.intro')){
      document.querySelector('.intro').remove()
    }
  }}, 7) */
}