// Libs
import gsap from 'gsap'
import barba from '@barba/core'
import barbaPrefetch from '@barba/prefetch'
import LocomotiveScroll from 'locomotive-scroll'

// Modules
import Trail from './modules/trail'
import Intro from './modules/intro'
import Time from './modules/time'
import SliderHome from './modules/sliderhome'
import About from './modules/about'
import Project from './modules/project'
import Gallery from './modules/gallery'
import Dissolve from './modules/dissolve'

/* Transitions */
barba.hooks.after((data) => {
  ga('set', 'page', window.location.pathname);
  ga('send', 'pageview');
})

//Enable smooth scroll
const scroll = new LocomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
  smooth: true
});

barba.use(barbaPrefetch)

barba.init({
  requestError: (trigger, action, url, response) => {
    if (action === 'click' && response.status && response.status === 404) {
      barba.go('/index');
    }
  },
  debug: false,
  sync: true,
  transitions: [
  {
    name: 'home',
    to: {
      namespace: [
        'home'
      ]
    },
    once(){
      Intro()
      SliderHome()
      Time()
    },
    beforeLeave(){
      scroll.destroy();
    },
    enter(){
    },
    after(){
      SliderHome()
      Time()
      scroll.init();
    }
  }, {
    name: 'all',
    to: {
      namespace: [
        'all'
      ]
    },
    beforeLeave(){
      scroll.destroy()
    },
    enter(){
      if(document.querySelector('#galleryImages')){
        Gallery()
      }
    },
    after(){
      scroll.init();
    }
  }, {
    name: 'about',
    to: {
      namespace: [
        'about'
      ]
    },
    once(){
      scroll.on('scroll', (instance) => {
        document.querySelector('.scroll-item').innerHTML = Math.trunc(instance.scroll.y);
      });
    },
    beforeLeave(){
      scroll.destroy();
    },
    enter(){
      About()
      Trail()
    },
    after(){
      if(document.querySelector('.scroll-item')){
        setTimeout(() => {
          scroll.init();
          scroll.on('scroll', (instance) => {
            document.querySelector('.scroll-item').innerHTML = Math.trunc(instance.scroll.y);
          });
        }, 100)
      }
    }
  }, {
    name: 'project',
    to: {
      namespace: [
        'project'
      ]
    },
    once(){
      Project()
      Dissolve()
      scroll.destroy()
      setTimeout(() => {
        scroll.init()
        const citationAppear = gsap.timeline({paused: true})
        citationAppear.to(".c-wrapper h3 .o-hidden div", {y: 0, duration: 0.3, stagger: 0.1, ease: "power2.inOut"})
        const showImage1 = gsap.timeline({paused: true})
        const showImage2 = gsap.timeline({paused: true})
        const showImage3 = gsap.timeline({paused: true})
        const showImage4 = gsap.timeline({paused: true})
        if(document.querySelector('#listPhotos')){showImage1.to("#listPhotos img", {clipPath: "inset(0% 0 0 0)", stagger: 0.75, ease: "circ"})}
        if(document.querySelector('#listPhotos2')){showImage2.to("#listPhotos2 img", {clipPath: "inset(0% 0 0 0)", stagger: 0.75, ease: "circ"})}
        if(document.querySelector('#listPhotos3')){showImage3.to("#listPhotos3 img", {clipPath: "inset(0% 0 0 0)", stagger: 0.75, ease: "circ"})}
        if(document.querySelector('#listPhotos4')){showImage4.to("#listPhotos4 img", {clipPath: "inset(0% 0 0 0)", stagger: 0.75, ease: "circ"})}

        scroll.on("call", value => {
          if (value === "revealCitation") {
            citationAppear.play()
          } else if (value === "showImage1") {
            showImage1.play()
          } else if (value === "showImage2") {
            showImage2.play()
          } else if (value === "showImage3") {
            showImage3.play()
          } else if (value === "showImage4") {
            showImage4.play()
          }
        });
      }, 2750)
    },
    beforeLeave(){
      scroll.destroy()
    },
    enter(){
      Project()
    },
    after(){
      Dissolve()
      setTimeout(() => {
        scroll.init()
        const citationAppear = gsap.timeline({paused: true})
        citationAppear.to(".c-wrapper h3 .o-hidden div", {y: 0, duration: 0.3, stagger: 0.1, ease: "power2.inOut"})
        const showImage1 = gsap.timeline({paused: true})
        const showImage2 = gsap.timeline({paused: true})
        const showImage3 = gsap.timeline({paused: true})
        const showImage4 = gsap.timeline({paused: true})
        if(document.querySelector('#listPhotos')){showImage1.to("#listPhotos img", {clipPath: "inset(0% 0 0 0)", stagger: 0.75, ease: "circ"})}
        if(document.querySelector('#listPhotos2')){showImage2.to("#listPhotos2 img", {clipPath: "inset(0% 0 0 0)", stagger: 0.75, ease: "circ"})}
        if(document.querySelector('#listPhotos3')){showImage3.to("#listPhotos3 img", {clipPath: "inset(0% 0 0 0)", stagger: 0.75, ease: "circ"})}
        if(document.querySelector('#listPhotos4')){showImage4.to("#listPhotos4 img", {clipPath: "inset(0% 0 0 0)", stagger: 0.75, ease: "circ"})}

        scroll.on("call", value => {
          if (value === "revealCitation") {
            citationAppear.play()
          } else if (value === "showImage1") {
            showImage1.play()
          } else if (value === "showImage2") {
            showImage2.play()
          } else if (value === "showImage3") {
            showImage3.play()
          } else if (value === "showImage4") {
            showImage4.play()
          }
        });
      }, 2750)
    }
  }]
});

/* Others */
//vh for mobile
const isDevice = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i);
if(isDevice){
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
} else {
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

//Credits
console.log(
  '%c 💦 Stay safe and wash your hands !',
  'font-family: Arial; font-size: 16px; padding: 10px; color: #fff;'
);