import * as THREE from 'three'
import gsap from 'gsap'

export default function Dissolve(){
  const images = document.querySelectorAll(".c-blister");
  const geometry = new THREE.PlaneBufferGeometry(1, 1, 80, 80);
  const textureLoader = new THREE.TextureLoader();
  const vertexShader = `
  float random (in vec2 st) {
    return fract(sin(dot(st.xy,vec2(12.9898,78.233)))* 43758.5453123);
  }

  // 2D Noise based on Morgan McGuire @morgan3d
  // https://www.shadertoy.com/view/4dS3Wd
  float noise (in vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);
    // Four corners in 2D of a tile
    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    // Smooth Interpolation
    // Cubic Hermine Curve.  Same as SmoothStep()
    vec2 u = f*f*(3.0-2.0*f);
    // u = smoothstep(0.,1.,f);
    // Mix 4 coorners percentages
    return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
  }
  
  uniform float u_time;
  uniform float u_centerY;
  varying vec2 vUv;
  varying vec3 vPos;
  void main(){
    vUv = uv;
    vPos = position;
    vec3 transformed = position;
    transformed.z += noise(transformed.xy * vec2(10, 10) + vec2(u_centerY, 0.0)) * 10.0;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(transformed, 1.0);
  }
  `
  const fragmentShader = `
    uniform float u_time;
    uniform sampler2D u_texture;
    varying vec2 vUv;
    varying vec3 vPos;
    
    void main(){
      vec2 uv = vUv;
      vec3 position = vPos;
      vec4 texture = texture2D(u_texture, uv);
      vec4 color = texture;
      gl_FragColor = color;
    }
  `
  const material = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader
  });
  
  /* const scroller = {
    target: document.querySelector(".d-image"),
    ease: 0.08,
    endY: 0,
    y: 0,
    maxY: 0,
    resizeRequest: 0,
    scrollRequest: 0,
  }; */
  
  class GL{
    constructor(canvas){
      this.canvas = canvas;
      this.W = this.canvas.clientWidth;
      this.H = this.canvas.clientHeight;
      this.PR = window.devicePixelRatio > 1 ? 1 : 1;
      this.init();
      this.bindEvents();
      this.planes = Array.from(images).map(el => new Plane(el, this));
    }
    
    init(){
      this.renderer = new THREE.WebGLRenderer({canvas: this.canvas, antialias: true, alpha: true});
      this.renderer.setSize(this.W * this.PR, this.H * this.PR, false);
      this.camera = new THREE.OrthographicCamera(-this.W/2, this.W/2, this.H/2, -this.H/2, 0.1, 10);
      this.camera.position.z = 10;
      this.scene = new THREE.Scene();
    }
    
    bindEvents(){
      window.addEventListener("resize", () => {
        this.onResize();
      });
    }
    
    onResize(){
      this.W = this.canvas.clientWidth;
      this.H = this.canvas.clientHeight;
      this.camera.left = -this.W / 2;
      this.camera.right = this.W / 2;
      this.camera.top = this.H / 2;
      this.camera.bottom = -this.H / 2;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.W * this.PR, this.H * this.PR, false);
      
      this.planes.forEach(plane => {
        plane.positionPlane();
      });
    }
    
  }
  
  class Plane{
    constructor(el, gl){
      this.gl = gl;
      this.el = el;
      this.geometry = geometry;
      this.material = material.clone();
      this.init();
    }
    
    init(){
      this.material.uniforms = {
        u_texture: {value: textureLoader.load(this.el.src)},
        u_scrollDiff: {value: 0.0001},
        u_scaleY: {value: 0.9999}
      }
      this.createPlane();
    }
    
    positionPlane(){
      const {left, top, width, height} = this.el.getBoundingClientRect();
      this.centerX = left + width/2 - this.gl.W/2;
      //this.centerY = -(top + height/2) + this.gl.H/2;
      this.centerY = -0.0078125;
      this.plane.position.x = this.centerX;
      //this.plane.position.y = this.centerY + scroller.y;
      this.plane.position.y = this.centerY;
      this.plane.position.z = 10;
      this.plane.scale.x = width;
      this.plane.scale.y = width * 1.3111040781;
    }

    createPlane(){
      this.plane = new THREE.Mesh(this.geometry, this.material);
      this.gl.scene.add(this.plane);
      this.positionPlane();
    }
    
    update(){
      //if(!this.plane) return;
      this.plane.position.y = this.centerY - scroller.y;
      this.material.uniforms.u_scrollDiff.value = scroller.y - scroller.endY;
    }
    
  }

  const gl = new GL(document.querySelector("#canvas"));
  gl.planes.forEach(plane => {
    gsap.set(plane.plane.position, {z: 0}),
    gsap.to(plane.plane.position, {
      //delay: 0.5,
      z: 10,
      duration: 2,
    });
  });

  render();

  function render(){
    console.warn = function(){}
    gl.renderer.render(gl.scene, gl.camera);
    requestAnimationFrame(render);
  }
  
}