import gsap from 'gsap'
import { Lethargy } from 'lethargy';
import * as THREE from 'three'
import barba from '@barba/core'

export default function SliderHome(){  
  const vertex = `
    uniform float scrolling;
    uniform float direction;
    varying vec2 vUv;

    float amplitude = 25.0;
    
    void main()	{
      vUv = uv;
      vec3 p = position;
      
      // Scroll
      float newX = ((((uv.y-direction)*(uv.y-direction))*amplitude)*scrolling)+p.x;
      p.x = newX;
      
      vec4 modelViewPosition = modelViewMatrix * vec4( p, 1.0 );
      
      gl_Position = projectionMatrix * modelViewPosition;
    }
  `;

  const fragment = `
      uniform sampler2D map;
      varying vec2 vUv;
      
      void main() {
        vec2 uv = vUv;
        gl_FragColor = texture2D(map, uv); 
      }
  `;

  // global variables
  const slidesNumber = 13;
  const slidesNumberSpace = slidesNumber * 2;
  const slideW = 70;
  const sliderW = slidesNumberSpace * slideW + 100;
  const imagesRatio = 1.3111040781;
  let cProjectIndex = 0;
  let timeoutGetNearest = undefined;

  let scene, camera, renderer, container, uniforms, materials, geometry, plane, intensity;

  // Event variables
  let direction = 0.0;
  let deltaX = 0;
  let targetX = 0;
  let mouseDown = false;
  let dragX = 0;
  let drag = false;

  const lethargy = new Lethargy();

  const urlProjects = [
    "the-veil",
    "vessel",
    "new-york",
    "milano",
    "street",
    "aerial",
    "london",
    "lifestyle-01",
    "gotham",
    "lifestyle-02",
    "oculus",
    "lines",
    "cooper"
  ]

  function init() {
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(75, window.innerWidth/window.innerHeight, 0.1, 500);
    camera.position.z = 85;
    renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true
    });
    console.warn = function(){};
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor(0xffffff, 0)
    renderer.setPixelRatio(window.devicePixelRatio)
    container = document.querySelector('.c-cover');
    container.appendChild(renderer.domElement);
    // Elements
    uniforms = {
      time: { type: "f", value: 0.0 },
      scrolling: { type: "f", value: 0.0 },
      map: { type: "t", value: null },
      direction: { type: "f", value: null }
    };
    const materialTemplate = new THREE.ShaderMaterial( {
      uniforms: uniforms,
      vertexShader: vertex,
      fragmentShader: fragment
    });
    materials = [];

    const loadManager = new THREE.LoadingManager();
    const loader = new THREE.TextureLoader(loadManager);

    for(let i = 0; i < slidesNumber; i++){
      geometry = new THREE.PlaneBufferGeometry(slideW, slideW * imagesRatio, 125, 125);
      geometry.userData = { 
        url: `/${urlProjects[i]}`
      };
      let material = materialTemplate.clone();
      material.transparent = true;
      materials.push(material);
      const texture = loader.load(`../images/blister-${i}.png`);
      material.uniforms.map.value = texture;
      texture.minFilter = THREE.LinearFilter;
      plane = new THREE.Mesh(geometry, material);
      plane.position.set(i * 2 * slideW, 0, 0)
      scene.add(plane);
    };
    
    const isDevice = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i);
    if(!isDevice){
      if(document.querySelector('.intro')){
        const loadingElem = document.querySelector('#loading');
        const spanLoading = loadingElem.querySelector('span');
        loadManager.onProgress = (urlOfLastItemLoaded, itemsLoaded, itemsTotal) => {
          setTimeout(() => {
            const progress = Math.round(itemsLoaded / itemsTotal * 100);
            spanLoading.innerHTML = `${progress}%`;
            if(progress == 100){
              gsap.to(spanLoading, {duration: 3, y: -100, ease: "power2"})
            }
          }, 1000)
        };
      }
      loadManager.onLoad = () => {
        window.onload = (() => {
          setTimeout(() => {
            gsap.to(".intro", {opacity: 0, duration: 1, ease: "power2.inOut"})
            gsap.to(".time, .header", {opacity: 1, y: 0, delay: 1, duration: 1, ease: "power3.inOut", onStart: () => {
              document.querySelector('.intro').remove()
            }})
            const nbObjects = scene.children;
            for(let i = 0; i < nbObjects.length; i++){
              gsap.set(nbObjects[i].position, {x: 0, y: 0, z: 0});
              gsap.set(nbObjects[i].scale, {x: 0.0001, y: 0.001})
              gsap.set(nbObjects[i].position, {x: 0.0001})
              gsap.set(nbObjects[i].rotation, {z: gsap.utils.random(-0.2, 0.2)})
              gsap.to(nbObjects[i].scale, {x: 1, y: 1, delay: 1, duration: 1, ease: "power2.inOut"})
              gsap.to(nbObjects[i].position, {x: i * 2 * slideW, delay: 2, duration: 1, ease: "power2.inOut"})
              gsap.to(nbObjects[i].rotation, {z: 0.0001, delay: 2, duration: 1, ease: "expo.inOut"})
            }
          }, 2500)
        })
      };
    } else {
      window.onload = (() => {
        setTimeout(() => {
          gsap.to(".intro", {opacity: 0, duration: 1, ease: "power2.inOut"})
          gsap.to(".time, .header", {opacity: 1, y: 0, delay: 1, duration: 1, ease: "power3.inOut", onStart: () => {
            document.querySelector('.intro').remove()
          }})
          gsap.to(".c-mobile", {duration: 1, opacity: 1, delay: 2, ease: "power2"})
        }, 3000)
      })
    }

    animate();
    
    if(document.querySelector('.slider-home')){
      document.querySelector('.slider-home').addEventListener('wheel', onMouseWheel, {passive: true});
      document.querySelector('.slider-home').addEventListener('mousedown', onMouseDown, {passive: true});
      document.querySelector('.slider-home').addEventListener('touchstart', onMouseDown, {passive: true});
      document.querySelector('.slider-home').addEventListener('mousemove', onMouseMove, {passive: true});
      document.querySelector('.slider-home').addEventListener('touchmove', onMouseMove, {passive: true});
      document.querySelector('.slider-home').addEventListener('mouseup', onMouseUp, {passive: true});
      document.querySelector('.slider-home').addEventListener('touchend', onMouseUp, {passive: true});
    }
  }

  function animate() {
    requestAnimationFrame(animate);
    for(let i = 0; i < materials.length; i++) {
      materials[i].uniforms.time.value += 0.1;
    }
    render();
  }

  function render() {
    targetX = Math.max(0, targetX);
    targetX = Math.min(sliderW - slideW, targetX);
    deltaX = (targetX - camera.position.x) * 0.12;
    intensity = clamp(deltaX/5, -1, 1);
    
    if (deltaX >= 0) {
      direction = 0.4;
    } else {
      direction = 0.6;
    }
    
    for(let i = 0; i < materials.length; i++) {
      materials[i].uniforms.scrolling.value = intensity;
      materials[i].uniforms.direction.value = direction;
    }

    camera.position.x += deltaX;
    renderer.render(scene, camera);
  }

  function onMouseWheel(event) {
    //event.preventDefault();
    targetX += event.deltaY / 10;
    let timeGetNearest = 100;
    if (lethargy.check(event) !== false) {
      timeGetNearest = 500;
    }
    if (timeoutGetNearest) clearTimeout(timeoutGetNearest);
    timeoutGetNearest = setTimeout(() => {
      targetX = getNearest();
    }, timeGetNearest);
  }

  function onMouseDown(event) {
    //event.preventDefault()
    drag = false;
    dragX = event.clientX;
    
    mouseDown = true;
    container.classList.add('container--active');
  }

  function onMouseMove(event) {
    //event.preventDefault()
    drag = true;
    let mouse = new THREE.Vector2();
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    // Drag and drop
    if (!mouseDown) {
      return;
    }
    targetX += (dragX - event.clientX) / 5;
    dragX = event.clientX;
  }

  function onMouseUp(event) {
    //event.preventDefault()
    mouseDown = false;
    container.classList.remove('container--active');
    if (timeoutGetNearest) clearTimeout(timeoutGetNearest);
    timeoutGetNearest = setTimeout(() => {
      targetX = getNearest();
    }, 500)

    // Hover
    let raycaster = new THREE.Raycaster();
    let mouse = new THREE.Vector2();
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);

    const nbObjects = scene.children
    const intersects = raycaster.intersectObjects(scene.children, true)

    for(let i = 0; i < intersects.length; i++){
      /* nbObjects.forEach((element) => {
        gsap.to(intersects[i].object.position, {z: 0})
        gsap.to(intersects[i].object.rotation, {z: 0})
        gsap.to(element.position, {x: intersects[i].object.position.x, ease: "power2.inOut", duration: gsap.utils.random(1, 1.5), onComplete: () => {
          location.href = intersects[i].object.geometry.userData.url
        }})
        gsap.to(element.rotation, {z: gsap.utils.random(-4, 4), duration: 0.5, ease: "expo.inOut"})
      }) */
      if(drag === false){
        barba.go(intersects[i].object.geometry.userData.url); 
      }
    }
  }

  function getNearest() {
    let latestDiff = sliderW;
    for (let i = 0; i < slidesNumberSpace; i += 2) {
      const slidePos = (slideW * i) + (slideW * 0.5);
      const diff = Math.abs((camera.position.x + slideW * 0.5) - slidePos);
      if (diff < latestDiff) {
        latestDiff = diff;
        cProjectIndex = i;
      }
    }
    return (slideW * cProjectIndex);
  }

  function clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }

  if(document.querySelector('.slider-home')){
    window.addEventListener( 'resize', onWindowResize, false );
  }
  function onWindowResize(){
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
  }

  init();
}