export default function Time(){  
  function clock(){
    let now = new Date()
    let nowFR = now.toLocaleString("fr-FR", {timeZone: "Europe/Paris", hour: '2-digit', minute: '2-digit', hour12: true}).replace("AM","").replace("PM","")
    let hourFR = now.toLocaleString("fr-FR", {timeZone: "Europe/Paris", hour: '2-digit', minute: '2-digit', hour12: false}).split(':')
    if(document.querySelector('.time-item')){
      document.querySelector('.time-item').innerHTML = nowFR
    }
    let ampm = (hourFR[0] >= 12) ? "PM" : "AM"
    if(document.querySelector('.time-system')){
      document.querySelector('.time-system').innerHTML = ampm
    }
    if(document.querySelector('.time')){
      document.querySelector('.time').style.opacity = "1";
    }
    setTimeout(clock, 1000)
  }

  clock()
}

/* if(document.querySelector('.time-item')){
  Time()
} */