import gsap from 'gsap'
import Draggable from '../libs/Draggable.min'
import InertiaPlugin from '../libs/InertiaPlugin.min'

export default function About(){
  gsap.registerPlugin(Draggable, InertiaPlugin);
  const tlAbout = gsap.timeline()
  tlAbout
  .set('.c-infos h2 div', {y: "25vh"})
  .set(".c-infos_desc", {opacity: 0})
  .to(".a-layer", {duration: 1, width: 0, ease: "power2"})
  .to('.c-infos h2 div', {duration: 1, y: 0, stagger: 0.1, ease: "power3"}, 0.25)
  .to(".c-infos_desc", {
    duration: 1, 
    opacity: 1,
    ease: "power2.inOut"
  }, 1)

  //Coded by
  Draggable.create(".calling-card", {
    dragResistance: 0.1,
    resistance: 0,
    inertia: true
  });
}

if(document.querySelector('.aside-footer')){
  About()
}